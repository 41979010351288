const { CLASS_IS_VISIBLE } = await import("@/util/layout");
const { setElementClass } = await import("@/util/elements");
const { ScrollIndicator } = await import("@/util/scroll-indicator");

const CLASS_CONTAINER = "js-table";
const CLASS_VIEWPORT = CLASS_CONTAINER + "__viewport";
const CLASS_TABLE_OVERLAY = CLASS_CONTAINER + "__overlay-";
const CLASS_TABLE_OVERLAY_LEFT = CLASS_TABLE_OVERLAY + "left";
const CLASS_TABLE_OVERLAY_RIGHT = CLASS_TABLE_OVERLAY + "right";

export class Tables {
    private static instance: Tables;

    private tables: HTMLCollectionOf<HTMLTableElement>;

    public static getInstance(): Tables {
        if (!Tables.instance) {
            Tables.instance = new Tables();
        }

        return Tables.instance;
    }

    private constructor() {
        this.tables = document.getElementsByTagName("table");
        this.addWrappers();
    }

    private addWrappers(): void {
        for (let index = 0; index < this.tables.length; index++) {
            const table = this.tables.item(index);
            if (!table) {
                return;
            }
            const parent = table.parentNode;
            if (!parent) {
                return;
            }
            const container = document.createElement("div");
            container.classList.add(CLASS_CONTAINER);

            const viewport = document.createElement("div");
            viewport.classList.add(CLASS_VIEWPORT);

            const left = document.createElement("div");
            left.classList.add(CLASS_TABLE_OVERLAY_LEFT);
            const right = document.createElement("div");
            right.classList.add(CLASS_TABLE_OVERLAY_RIGHT);

            // Wrappers are needed for horizontal scrolling, they have to be added programatically because html for text content element tables can't be changed
            container.appendChild(left);
            container.appendChild(viewport);
            container.appendChild(right);

            parent.insertBefore(container, table);
            viewport.appendChild(table);

            const scrollIndicators = {
                left,
                right,
            };

            Object.keys(scrollIndicators).forEach(key => {
                const direction = key as keyof typeof scrollIndicators;
                const scrollIndicator = new ScrollIndicator({
                    container,
                    viewport,
                    content: table,
                    direction,
                });

                scrollIndicator.watch((detached) => {
                    setElementClass(scrollIndicators[direction], CLASS_IS_VISIBLE, detached);
                });
            });
        }
    }
}
